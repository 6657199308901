import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  GetApplicationsDocument,
  RoleType,
  useCreateRoleMutation,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";
import gql from "graphql-tag";

gql`
  mutation CreateRole($input: CreateRoleInputType!) {
    createRole(input: $input) {
      ...RoleParts
    }
  }
`;

export default function () {
  const mutation = useCreateRoleMutation({});

  function call(applicationId: string, role: RoleType) {
    mutation.mutate(
      {
        input: {
          name: role.name,
          description: role.description ?? "",
          applicationId: applicationId,
          attribute: role.attribute,
          assigneeOrganizationIds:
            role.assignees?.flatMap(
              (element) => element?.organizationId ?? []
            ) ?? [],
        },
      },
      {
        refetchQueries: [{ query: GetApplicationsDocument }],
        update: (cache, { data: update }) => {
          const parentQuery: QueryOptions<
            GetApplicationQueryVariables,
            GetApplicationQuery
          > = {
            query: GetApplicationDocument,
            variables: {
              applicationId: applicationId,
            },
          };
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              application: {
                ...cachedData?.application,
                roles: [
                  ...(cachedData?.application?.roles ?? []),
                  { ...update?.createRole },
                ],
              },
            },
          });
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
